import IconBatteryCharging from '@/components/icons/IconBatteryCharging.vue'
import IconCircleCurrency from '@/components/icons/IconCircleCurrency.vue'
import IconDelete from '@/components/icons/IconDelete.vue'
import IconFire from '@/components/icons/IconFire.vue'
import IconOutlinedMarkerCheck from '@/components/icons/IconOutlinedMarkerCheck.vue'
import IconPliers from '@/components/icons/IconPliers.vue'
import IconWaterDrop from '@/components/icons/IconWaterDrop.vue'
import VehiclesIcon from '@/components/ui-elements/custom-icons/VehiclesIcon.vue'
import {
  powerTripBlue,
  powerTripDarkBlue,
  powerTripDarkGrey,
  powerTripLightGrey,
  powerTripRed,
  powerTripWhite,
} from '@/data/const'
import { h } from 'vue'
import { createVuetify, type IconProps, type IconSet, type ThemeDefinition } from 'vuetify'
import { mdi } from 'vuetify/iconsets/mdi'
import { VTimePicker } from 'vuetify/labs/VTimePicker'
import 'vuetify/styles'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customSvgNameToComponent: any = {
  vehiclesIcon: VehiclesIcon,
  circleCurrencyIcon: IconCircleCurrency,
  deleteIcon: IconDelete,
  outlinedMarkerCheck: IconOutlinedMarkerCheck,
  fireIcon: IconFire,
  pliers: IconPliers,
  waterDrop: IconWaterDrop,
  batteryCharging: IconBatteryCharging,
}

const customSVGs: IconSet = {
  component: (props: IconProps) => h(customSvgNameToComponent[props.icon as string]),
}

const customLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    background: powerTripWhite,
    primary: powerTripBlue,
    secondary: powerTripLightGrey,
    tertiary: powerTripDarkGrey,
    accent: powerTripDarkBlue,
    error: powerTripRed,
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
  },
}

export default createVuetify({
  icons: {
    defaultSet: 'mdi',
    sets: {
      mdi,
      custom: customSVGs,
    },
  },
  components: {
    VTimePicker,
  },
  theme: {
    defaultTheme: 'customLightTheme',
    themes: {
      customLightTheme,
    },
  },
})
