<template>
  <v-list style="min-width: 250px" class="bg-background rounded-lg pa-3">
    <v-list-item>
      <v-row no-gutters>
        <v-avatar color="primary" class="mr-3 mt-1">
          <img v-if="avatarSrc" :src="avatarSrc" width="100%" height="auto"/>
          <v-icon v-else dark> mdi-account-circle </v-icon>
        </v-avatar>
        <v-card-subtitle class="d-flex flex-column text-grey pt-0">
          <span class="font-weight-medium text-h6">
            {{ user ? user.fullName : '' }}
          </span>
          <span>
            {{ user ? user.email : '' }}
          </span>
        </v-card-subtitle>
        <v-spacer />
      </v-row>
    </v-list-item>
    <v-divider class="mb-3" />
    <v-list-item @click="handleChangeDashboardTab('account')">
      <v-list-item-title> Manage account </v-list-item-title>
    </v-list-item>
    <v-list-item @click="handleChangeDashboardTab('fleet-vehicles')">
      <v-list-item-title> My fleet </v-list-item-title>
    </v-list-item>
    <v-list-item @click="handleChangeDashboardTab('vehicle-details')">
      <v-list-item-title> Vehicle settings </v-list-item-title>
    </v-list-item>
    <v-list-item @click="handleChangeDashboardTab('defaults')">
      <v-list-item-title> Trip planning defaults </v-list-item-title>
    </v-list-item>
    <v-divider class="mb-3" />
    <v-list-item @click="handleLogout">
      <v-list-item-title
        class="text-decoration-underline text-accent"
        style="text-underline-offset: 4px"
      >
        Sign out
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script lang="ts" setup>
import Driver from '@/classes/user_classes/driver'
import {
  ActionTypes,
  type DashboardDialogContent,
  GettersTypes,
  MutationTypes,
  type State,
} from '@/store/store_types'
import { computed } from 'vue'
import { useStore } from 'vuex'

const emit = defineEmits(['close'])
const store = useStore<State>()

const driver = computed<Driver | undefined>(() => store.getters[GettersTypes.currentDriver])
const user = computed(() => store.state.user)
const avatarSrc = computed(() => driver.value?.profilePicSrc)

function handleLogout() {
  emit('close')
  store.dispatch(ActionTypes.logoutUser)
}

function handleChangeDashboardTab(tab: DashboardDialogContent) {
  store.commit(MutationTypes.setDashboardDialogContent, tab)
}
</script>
