<template>
  <v-row align="center" justify="center" class="mb-7" no-gutters>
    <v-btn
      large
      icon
      @click="decrementValue"
      flat
      color="transparent"
      class="border-thin border-tertiary"
    >
      <v-icon large color="tertiary"> mdi-minus </v-icon>
    </v-btn>
    <v-text-field
      :model-value="passedValue ?? null"
      class="rounded-lg center-aligned-input none-underlined-input pt-5 mx-3"
      type="number"
      hide-spin-buttons
      :rules="[validateValue]"
      @update:model-value="handleSetValue"
      :suffix="suffix"
      :prefix="prefix"
      :error-messages="errorMessages"
    ></v-text-field>
    <v-btn
      large
      icon
      @click="incrementValue"
      flat
      color="transparent"
      class="border-thin border-tertiary"
    >
      <v-icon large color="tertiary"> mdi-plus </v-icon>
    </v-btn>
  </v-row>
</template>

<script setup lang="ts">
import parseIntOrFloat from '@/utils/parseIntOrFloat'

interface Props {
  passedValue?: number
  wholeNumber?: boolean
  allowNegatives?: boolean
  errorMessages?: string | string[] | undefined
  suffix?: string
  prefix?: string
}

const {
  passedValue = 0,
  wholeNumber = false,
  allowNegatives = false,
  errorMessages = undefined,
} = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update', value: number): void
}>()

function emitUpdate(val: number): void {
  emit('update', val)
}

function incrementValue(): void {
  if (!passedValue) {
    handleSetValue(1)
    return
  }
  const parsedVal = parseIntOrFloat(passedValue)
  if (!parsedVal) return
  handleSetValue(parsedVal + 1)
}

function decrementValue(): void {
  if (passedValue === null) return
  if (!allowNegatives && passedValue <= 0) return
  const parsedVal = parseIntOrFloat(passedValue)
  if (parsedVal === undefined) return
  handleSetValue(parsedVal - 1)
}

function validateValue(val: number | string | null): boolean | string {
  if (val === null || val === 0 || val === '0') return true
  const parsedVal = parseIntOrFloat(val)
  if (!parsedVal) return 'needs to be a number'
  if (wholeNumber && !Number.isInteger(parsedVal)) return 'needs to be an whole number'
  return true
}

function handleSetValue(val: number | string | null): void {
  if (val === null || val === 0 || val === '0') {
    emitUpdate(0)
    return
  }
  const parsedVal = parseIntOrFloat(val)
  if (!parsedVal) return
  if (wholeNumber && !Number.isInteger(parsedVal)) return
  emitUpdate(parsedVal)
}
</script>

<style scoped>
#battery-custom-input {
  /* position off set centered element 145px width within a 80% width container */
  padding-left: calc(40% - 108px);
  padding-right: calc(40% - 37px);
}

.right-aligned-input :deep(input) {
  text-align: right;
}

.center-aligned-input :deep(input) {
  text-align: center;
}

.center-aligned-input :deep(.v-messages__message) {
  text-align: center;
}

.none-underlined-input :deep(.v-input__slot::before) {
  border-style: none !important;
}

.off-set-input-message :deep(.v-messages__message) {
  padding-top: 16px;
}
</style>
