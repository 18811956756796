import type { DirectusManagedContent } from '@/types/directus-types'
import { directusReadByQuery } from './helperFunctions'

/** API Call to fetch managed content from DB. */
export async function fetchManagedContent(): Promise<DirectusManagedContent[] | undefined> {
  const collection = 'ManagedContent'
  const query = { limit: -1 }

  const res = await directusReadByQuery<DirectusManagedContent>(collection, query)
  return res
}
