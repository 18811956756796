import type { DirectusSchema } from '@/types/directus-types'
import { authentication, createDirectus, rest } from '@directus/sdk'

// get base path
const elementData = document?.querySelector('powertrip-optimiser')

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const passedSSOName = elementData?.attributes?.['sso' as any]?.nodeValue // this version of ts has issue typing dom objects correctly hence the any. Remove if this is no longer true.

/** Base URL for the directus services and storage. */
export const directusBaseURL =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  elementData?.attributes?.['backend' as any]?.nodeValue ?? 'https://backend.powertrip.earth'

export const chargerCustomEndpoint = directusBaseURL + '/charger/locations/all'
export const modelsCustomEndpoint = directusBaseURL + '/charger/vehicles/all'

/** Directus asset storage path extension. */
export const directusAssetsRoute = directusBaseURL + '/assets/'

/**
 * Accepted strings for directus collection names.
 *
 * NOTE: there should also be a file handling all interactions with each collection
 * e.g. `asset-bookings-calls.ts` for `"Asset_Bookings"`.
 */
export type CollectionNames =
  | 'Asset_Bookings'
  | 'Car_Record'
  | 'Driver'
  | 'EV_Model_Assets'
  | 'ManagedContent'
  | 'NetworkBranding'
  | 'Optimiser_Settings'
  | 'Favourite_Locations'
  | 'SavedRoutePlans'
  | 'Car_Record_files'

export type SingletonNames = 'Company' | 'ScoringConfig'

class LocalStorage {
  get() {
    return JSON.parse(localStorage.getItem('directus-data') || '{}')
  }
  set(data) {
    localStorage.setItem('directus-data', JSON.stringify(data))
  }
  clear() {
    localStorage.removeItem('directus-data')
  }
}

export const storage = new LocalStorage()

/** Local directus client. */
const directus = createDirectus<DirectusSchema>(directusBaseURL)
  .with(authentication('json', { storage }))
  .with(
    rest({
      onRequest: (options) => ({
        ...options,
        headers: {
          ...options.headers,
          'cache-control': 'no-store',
        },
      }),
    }),
  )

export default directus
