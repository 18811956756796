import type {
  DirectusCardRecordFiles,
  DirectusModelImageLink,
  DirectusUpdateVehicleImagesRes,
  DirectusUserSelectedPlug,
  DirectusVehicle,
  DirectusVehicleVariableData,
} from '@/types/directus-types'
import { uploadFile } from './file-calls'
import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import { directusCreateOne, directusReadByQuery, directusUpdateOne } from './helperFunctions'

/**
 * Uploads an image to the "Car_Record_files" collection and links it to the passed vehicle directus id.
 *
 * @param directus_vehicle_id the id of the vehicle to link the uploaded image to.
 * @param imageToAdd the image to upload.
 * @returns an object containing the success status and the UUID of the uploaded image.
 */
export async function updateVehicleImages(
  directus_vehicle_id: number,
  imageToAdd: File,
): Promise<DirectusUpdateVehicleImagesRes> {
  const collection = 'Car_Record_files'
  try {
    const uploadRes = await uploadFile(imageToAdd, true)
    if (uploadRes.success && uploadRes.UUID) {
      await directusCreateOne<DirectusCardRecordFiles>(collection, {
        Car_Record_id: directus_vehicle_id,
        directus_files_id: uploadRes.UUID,
      })
      return {
        success: true,
        imageUUID: uploadRes.UUID,
      }
    } else {
      return { success: false }
    }
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
    return { success: false }
  }
}

/**
 * API Call to update a vehicle records model in directus.
 *
 * @param vehicleID the ID of the vehicle record to update.
 * @param newSOH the new state of health value to update the vehicle record with.
 * @returns the updated `Vehicle` collection record if successful, undefined if not.
 */
export async function updateVehicleSOH(
  vehicleID: number,
  newSOH: number,
): Promise<DirectusVehicle | undefined> {
  const collection = 'Car_Record'
  const res = await directusUpdateOne<DirectusVehicle>(collection, vehicleID, { soh: newSOH })
  return res
}

/**
 * API Call to update a vehicle records model in directus.
 *
 * @param vehicleID the ID of the vehicle record to update.
 * @param modelID the ID of the model to update the vehicle record with.
 * @returns the updated `Vehicle` collection record if successful, undefined if not.
 */
export async function updateVehicleModel(
  vehicleID: number,
  modelID?: string,
): Promise<DirectusVehicle | undefined> {
  const collection = 'Car_Record'
  const res = await directusUpdateOne<DirectusVehicle>(collection, vehicleID, {
    CDB_Model_ID: modelID,
  })
  return res
}

/**
 * API Call to update a `Vehicle` collection records `UserSelectedPlugs` array in directus.
 *
 * @param vehicleID the ID of the vehicle record to update.
 * @param plugs the array of `DirectusUserSelectedPlug` objects to update with.
 * @returns the updated `Vehicle` collection record if successful, undefined if not.
 */
export async function updateVehiclePlugs(
  vehicleID: number,
  plugs: DirectusUserSelectedPlug[] | null,
): Promise<DirectusVehicle | undefined> {
  let convertedPlugs: string[] | null = null
  if (plugs) {
    // convert the plugs to an array of strings by stringifying each of the objects.
    convertedPlugs = plugs.map((plug) => JSON.stringify(plug))
  }
  const collection = 'Car_Record'
  // update the directus record with the UserSelectedPlugs array.
  const res = await directusUpdateOne<DirectusVehicle>(collection, vehicleID, {
    UserSelectedPlugs: convertedPlugs,
  })
  return res
}

/**
 * API call to fetch fleet vehicles.
 *
 * @returns a list of directus `VehicleData` collection objects.
 */
export async function fetchFleetVehicles(): Promise<DirectusVehicle[] | undefined> {
  const collection = 'Car_Record'
  const query = {
    limit: -1,
    fields: [
      'id',
      'Driver',
      'rego',
      'vin',
      'name',
      'fuel_type',
      'soh',
      'UserSelectedPlugs',
      'Latitude',
      'Longitude',
      'speed',
      'LastKnown',
      'StateOfCharge',
      'Ignition_On',
      'Images.directus_files_id',
      'CDB_Model_ID',
      'AdvancedConfig',
      'from_slurper',
    ],
  }

  const res = await directusReadByQuery<DirectusVehicle>(collection, query)
  return res
}

/**
 * API call to fetch image asset links for all supported EV models from directus.
 *
 * @returns a list of `DirectusModelImageLink` link objects.
 */
export async function fetchEVModelImageLinks(): Promise<DirectusModelImageLink[] | undefined> {
  const collection = 'EV_Model_Assets'
  const query = { limit: -1 }

  const res = await directusReadByQuery<DirectusModelImageLink>(collection, query)
  return res
}

/**
 * API call to create a new directus `Vehicle` collection record.
 *
 * @param vehicleData the whole `DirectusVehicleCreationData` object.
 * @returns a new directus `Vehicle` collection record if successful (`VehicleData` object).
 */
export async function createNewVehicle(
  vehicleData: DirectusVehicleVariableData,
): Promise<DirectusVehicle | undefined> {
  const collection = 'Car_Record'
  const res = await directusCreateOne<DirectusVehicleVariableData>(collection, vehicleData)
  return res as unknown as DirectusVehicle
}

/**
 * Updates an existing directus `Vehicle` collection record.
 *
 * @param directusId - The id of the directus `Vehicle` collection record to update.
 * @param dataToUpdate - An object containing the vehicle data to be updated.
 * @returns The updated `DirectusVehicle` object if successful.
 */

export async function updateVehicle(
  directusId: number,
  dataToUpdate: DirectusVehicleVariableData,
): Promise<DirectusVehicle> {
  const collection = 'Car_Record'
  const res = await directusUpdateOne<DirectusVehicleVariableData>(
    collection,
    directusId,
    dataToUpdate,
  )
  return res as unknown as DirectusVehicle
}
