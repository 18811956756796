<template>
  <div class="pwt-breadcrumb-row">
    <div
      class="pwt-breadcrumb-wrapper"
      v-for="(item, index) in items"
      :key="'breadcrumb-item-' + index"
      @click="() => breadcrumbNavigation(item)"
      :style="'max-width: ' + Math.min(100 / items.length, 20) + '%;'"
    >
      <span v-if="index > 0" class="pwt-breadcrumb-separator"> / </span>
      <span class="pwt-breadcrumb" :class="{ 'pwt-breadcrumb-active': isActive(item) }">
        {{ item.title }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  useRoute,
  useRouter,
  type RouteLocationAsPathGeneric,
  type RouteLocationRaw,
} from 'vue-router'

export interface BreadcrumbsItem {
  title: string
  to: RouteLocationRaw
  disabled?: boolean
  preserveQuery?: boolean
}

const { items } = defineProps<{
  items: BreadcrumbsItem[]
  homeHref?: string
  showHomeBtn?: boolean
}>()
const route = useRoute()
const router = useRouter()

function breadcrumbNavigation(item: BreadcrumbsItem) {
  if (item.disabled) return
  let newRoute: RouteLocationRaw = {}
  if (typeof item.to === 'object') {
    newRoute = item.to
  } else if (typeof item.to === 'string') {
    ;(newRoute as unknown as RouteLocationAsPathGeneric).path = item.to
  }

  if (item.preserveQuery) {
    newRoute.query = route.query
  }
  router.push(newRoute)
}

function isActive(item: BreadcrumbsItem): boolean {
  if (typeof item.to === 'string') {
    return route.path === item.to
  }

  if (typeof item.to === 'object') {
    if (item.to.path) {
      return route.path === item.to.path
    }

    if ('name' in item.to && typeof item.to.name === 'string') {
      return route.name === item.to.name
    }
  }

  return false
}
</script>

<style scoped>
.pwt-breadcrumb-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: 8px;
  width: 90%;
}

.pwt-breadcrumb-wrapper {
  flex-shrink: 1;
  flex-grow: 1;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  width: fit-content;
}

.pwt-breadcrumb {
  cursor: pointer;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  font-size: small;
}

.pwt-breadcrumb-wrapper:hover .pwt-breadcrumb {
  text-decoration: underline;
}

.pwt-breadcrumb-separator {
  margin: 0 8px;
  color: #6a6a6a;
}

.pwt-breadcrumb-active {
  color: #0099ff;
}
</style>
