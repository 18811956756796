<template>
  <div class="pwt-trip-nav-bar">
    <TripsHomeBtn v-if="showBreadcrumbs" />
    <CustomBreadcrumbs v-if="showBreadcrumbs" :items="items" />
  </div>
</template>

<script setup lang="ts">
import type Trip from '@/classes/trip_classes/trip'
import { RouteNames } from '@/router'
import { GettersTypes } from '@/store/store_types'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useDisplay } from 'vuetify'
import { useStore } from 'vuex'
import TripsHomeBtn from '../buttons/TripsHomeBtn.vue'
import CustomBreadcrumbs, { type BreadcrumbsItem } from './CustomBreadcrumbs.vue'

const store = useStore()
const route = useRoute()
const trip = computed<Trip | undefined>(() => store.getters[GettersTypes.selectedTripData])
const { smAndDown } = useDisplay()

const showBreadcrumbs = computed(() => {
  if (trip.value && route.name !== RouteNames.trips) return true
  if (route.name === RouteNames.tripAddOrigin) return true
  if (route.name === RouteNames.tripAddDetails) return true
  if (route.name === RouteNames.tripAddStops) return true
  if (route.name === RouteNames.tripItinerary) return true
  if (route.name === RouteNames.tripStats) return true
  return false
})

// --- possible breadcrumbs --- //
const destShort = {
  title: 'Destination',
  to: { name: RouteNames.tripAddDestination },
  preserveQuery: true,
}
const destLong = {
  title: 'Add destination',
  to: { name: RouteNames.tripAddDestination },
  preserveQuery: true,
}
const origShort = {
  title: 'Origin',
  to: { name: RouteNames.tripAddOrigin },
  preserveQuery: true,
}
const origLong = {
  title: 'Add origin',
  to: { name: RouteNames.tripAddOrigin },
  preserveQuery: true,
}
const detailsShort = {
  title: 'Details',
  to: { name: RouteNames.tripAddDetails },
  preserveQuery: true,
}
const detailsLong = {
  title: 'Add details',
  to: { name: RouteNames.tripAddDetails },
  preserveQuery: true,
}
const stopsShort = {
  title: 'Stops',
  to: { name: RouteNames.tripAddStops },
  preserveQuery: true,
}
const stopsLong = {
  title: 'Add stops',
  to: { name: RouteNames.tripAddStops },
  preserveQuery: true,
}
const vehicleShort = {
  title: 'Vehicle',
  to: { name: RouteNames.tripSelectVehicle },
  preserveQuery: true,
}
const vehicleLong = {
  title: 'Select vehicle',
  to: { name: RouteNames.tripSelectVehicle },
  preserveQuery: true,
}
const itinerary = {
  title: 'Itinerary',
  to: { name: RouteNames.tripItinerary },
  preserveQuery: true,
}
const stats = {
  title: 'Stats',
  to: { name: RouteNames.tripStats },
  preserveQuery: true,
}

// compile breadcrumbs
const items = computed<BreadcrumbsItem[]>(() => {
  const plannedTrip = !!(trip.value && trip.value.status && trip.value.status !== 'unplanned')
  const useShort = smAndDown.value

  const allBreadcrumbsShort = [
    destShort,
    origShort,
    detailsShort,
    stopsShort,
    vehicleShort,
    itinerary,
  ]
  const allBreadcrumbsLong = [destLong, origLong, detailsLong, stopsLong, vehicleLong, itinerary]

  if (route.name === RouteNames.tripAddDestination) {
    if (plannedTrip) return useShort ? allBreadcrumbsShort : allBreadcrumbsLong
    return []
  }

  if (route.name === RouteNames.tripAddOrigin) {
    if (plannedTrip) return useShort ? allBreadcrumbsShort : allBreadcrumbsLong
    return useShort ? [destShort, origShort] : [destLong, origLong]
  }

  if (route.name === RouteNames.tripAddDetails) {
    if (plannedTrip) return useShort ? allBreadcrumbsShort : allBreadcrumbsLong
    return useShort ? [destShort, origShort, detailsShort] : [destLong, origLong, detailsLong]
  }

  if (route.name === RouteNames.tripAddStops) {
    if (plannedTrip) return useShort ? allBreadcrumbsShort : allBreadcrumbsLong
    return useShort
      ? [destShort, origShort, detailsShort, stopsShort]
      : [destLong, origLong, detailsLong, stopsLong]
  }

  if (route.name === RouteNames.tripSelectVehicle) {
    if (plannedTrip) return useShort ? allBreadcrumbsShort : allBreadcrumbsLong
    return useShort
      ? [destShort, origShort, detailsShort, stopsShort, vehicleShort]
      : [destLong, origLong, detailsLong, stopsLong, vehicleLong]
  }

  if (route.name === RouteNames.tripItinerary) {
    return useShort ? allBreadcrumbsShort : allBreadcrumbsLong
  }

  if (route.name === RouteNames.tripStats) {
    return useShort ? [...allBreadcrumbsShort, stats] : [...allBreadcrumbsLong, stats]
  }

  return []
})
</script>

<style>
.pwt-trip-nav-bar {
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 20px;
  width: calc(100% - 40px);
}
</style>
