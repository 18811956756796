// Color palate
export const powerTripDarkBlue = '#2E46ff'
export const powerTripBlue = '#2EAEFF'
export const powerTripWhite = '#FAFAFA'
export const powerTripDarkGrey = '#2b2b2b'
export const powerTripLightGrey = '#9E9E9E'
export const powerTripRed = '#FF5B5B'
export const powerTripMidGrey = '#FAFAFA'
export const powertripAzure80 = '#6DC6FF'

// commonly used strings
export const Saved_Trips = 'Saved_Trips'

// magic numbers

/**
 * Mean passenger weight in kg.
 *
 * rounded to nearest kg based on mean weight adult NZ in 2023 combined for
 * genders and ethnicity category (this does not take into account the
 * density of the sample groups).
 */
export const meanPassengerWeight = 85 // TODO: find better solution that google search data.

/**
 * Max number of waypoints ev nav route planning can support.
 */
export const evNavMaxWaypoints = 2
