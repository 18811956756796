import { DateTime, Duration, type DurationLikeObject } from 'luxon'

export function convertEpochTo24hrTime(epochTime: number) {
  return DateTime.fromMillis(epochTime).toLocaleString(DateTime.TIME_24_SIMPLE)
}

export function convert24hrTimeToEpoch(
  timeString: string,
  oldEpochTime: number | undefined = undefined,
) {
  const dateTimeObj = oldEpochTime ? DateTime.fromMillis(oldEpochTime) : DateTime.now()

  const splitTimeString = timeString.split(':')

  const returnObj = DateTime.fromObject({
    year: dateTimeObj.year,
    month: dateTimeObj.month,
    day: dateTimeObj.day,
    hour: parseInt(splitTimeString[0]),
    minute: parseInt(splitTimeString[1]),
  })

  return returnObj.toMillis()
}

export function convertEpochToISODateString(epochTime: number) {
  return DateTime.fromMillis(epochTime).toISODate()
}

export function convertISODateStringToEpoch(ISOString: string, oldEpochTime: number) {
  const epochDateTimeObj = DateTime.fromMillis(oldEpochTime)
  const ISODateTimeObj = DateTime.fromISO(ISOString)

  const returnObj = DateTime.fromObject({
    year: ISODateTimeObj.year,
    month: ISODateTimeObj.month,
    day: ISODateTimeObj.day,
    hour: epochDateTimeObj.hour,
    minute: epochDateTimeObj.minute,
  })

  return returnObj.toMillis()
}

/**
 * Converts a epoch time stamp (milliseconds from epoch) to a human readable display string.
 *
 * @param epochTime milliseconds from epoch
 * @returns display string
 */
export function epochToNiceTime(epochTime: number): string {
  return DateTime.fromMillis(epochTime).toLocaleString(DateTime.TIME_SIMPLE)
}

/**
 * Converts a epoch time stamp (milliseconds from epoch) to a human readable display string.
 *
 * @param epochTime milliseconds from epoch
 * @returns display string
 */
export function epochToNiceDate(epochTime: number): string {
  return DateTime.fromMillis(epochTime).toLocaleString(DateTime.DATE_HUGE)
}

/**
 * Returns a human readable display string for a given duration in seconds.
 * @param seconds duration in seconds
 * @returns human readable display string
 */
export function getNiceDuration(
  seconds: number,
  display: 'short' | 'long' | 'narrow' = 'narrow',
): string {
  const timeObj: DurationLikeObject = {
    minutes: Math.round(seconds / 60),
  }
  if (seconds >= 3600) {
    timeObj.hours = 0
  }

  return Duration.fromObject(timeObj)
    .normalize()
    .toHuman(
      display === 'long'
        ? { unitDisplay: 'long' }
        : display === 'short'
          ? { unitDisplay: 'short' }
          : { listStyle: 'narrow' },
    )
    .replace(',', '')
}
