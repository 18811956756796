import type { DirectusCompany, DirectusScoringConfig } from '@/types/directus-types'
import { directusReadSingletonItem } from './helperFunctions'

/** API Call to fetch the company details from the DB.
 *
 * @returns a user `CompanyData` object if successful. Undefined if not.
 */
export async function fetchFleetData(): Promise<DirectusCompany | undefined> {
  const collection = 'Company'
  const query = {
    fields: ['Name', 'Logo', 'ManualVehicleMatching'],
  }

  const res = await directusReadSingletonItem<DirectusCompany>(collection, query)
  return res
}

/** API Call to fetch the config details from the DB.
 *
 * @returns a user `DirectusFleetConfig` object if successful. Undefined if not.
 */
export async function fetchFleetConfigData(): Promise<DirectusScoringConfig | undefined> {
  const collection = 'ScoringConfig'
  const query = {
    fields: ['dcCostPerKwh', 'acCostPerKwh', 'petrolCostPerLitre', 'dieselCostPerLitre'],
  }

  const res = await directusReadSingletonItem<DirectusScoringConfig>(collection, query)
  return res
}
