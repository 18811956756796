<template>
  <div class="d-flex align-center">
    <StyledToolTip v-if="SoCTooltip" :data="SoCTooltip" />
    <div
      class="rounded-pill bg-grey-lighten-3 flex-grow-1 flex-shrink-1"
      :style="`
        width: 100%;
        height: 18px;
        box-shadow: inset -2px 3px 2px rgba(0, 0, 0, 0.2);
        overflow: hidden;`"
    >
      <div class="bg-grey rounded-pill" style="width: 1%; height: 100%"></div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import type { State } from '@/store/store_types';
import { computed } from 'vue';
import { useStore } from 'vuex';
import StyledToolTip from './StyledToolTip.vue';

const store = useStore<State>()
const SoCTooltip = computed(() =>
  store.state.managedContent.find(
    (content) => content.Description === 'vehicle details SoC failed tooltip',
  ),
)
</script>
