<template>
  <v-row no-gutters justify="space-between" class="flex-nowrap">
    <v-text-field
      v-model="hoursValue"
      type="number"
      class="pwt-number-font"
      @update:model-value="updateStayDuration"
    >
      <template v-slot:label>
        <span class="text-black" style="opacity: 1 !important"> Hours </span>
      </template>
    </v-text-field>
    <v-text-field
      v-model="minuteValue"
      type="number"
      class="pwt-number-font ml-3"
      @update:model-value="updateStayDuration"
    >
      <template v-slot:label>
        <span class="text-black" style="opacity: 1 !important"> Minutes </span>
      </template>
    </v-text-field>
  </v-row>
</template>
<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { Duration } from 'luxon'

export interface StayDurationInputUpdateObj {
  identifier: string
  duration: number
}

interface Props {
  initialValue: number
  identifier: string
}

const emit = defineEmits<{
  (e: 'update', value: StayDurationInputUpdateObj): void
}>()

const { initialValue, identifier } = defineProps<Props>()

const hoursValue = ref(0)
const minuteValue = ref(0)

function setInitialValue() {
  const durationObj = Duration.fromObject({ seconds: initialValue })
    .shiftTo('hours', 'minutes')
    .toObject()
  hoursValue.value = durationObj.hours || 0
  minuteValue.value = durationObj.minutes || 0
}

function updateStayDuration() {
  const durationObj = Duration.fromObject({
    hours: hoursValue.value,
    minutes: minuteValue.value,
  })
    .shiftTo('seconds')
    .toObject()
  emit('update', {
    identifier,
    duration: durationObj.seconds ?? 0,
  })
}

onMounted(() => {
  setInitialValue()
})

watch(
  () => initialValue,
  () => {
    setInitialValue()
  },
)
</script>
