<template>
  <v-btn icon @click="clearTripAndReturnToTrips" flat density="comfortable" color="transparent">
    <!-- <v-icon color="primary">mdi-exit-to-app</v-icon> -->
    <v-icon color="primary">mdi-chevron-left</v-icon>
  </v-btn>
</template>
<script setup lang="ts">
import { RouteNames } from '@/router'
import { MutationTypes } from '@/store/store_types'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const router = useRouter()
const store = useStore()

function clearTripAndReturnToTrips() {
  store.commit(MutationTypes.setSelectedTrip, undefined)
  store.commit(MutationTypes.setDisplayAllChargersAlongRoute, false)
  router.push({ name: RouteNames.trips })
}
</script>
