<template>
  <GenericDialog :activator="activator" @close="() => emit('close')">
    <v-card-title> Add new favourite location </v-card-title>
    <FavLocationForm @close="() => emit('close')" :initialValue="initialValue" />
  </GenericDialog>
</template>
<script lang="ts" setup>
import type FavouriteLocation from '@/classes/favouriteLocation'
import GenericDialog from '../dialog-wrappers/GenericDialog.vue'
import FavLocationForm from './FavLocationForm.vue'

const { activator, initialValue = undefined } = defineProps<{
  activator: boolean
  initialValue?: FavouriteLocation
}>()
const emit = defineEmits<{ (e: 'close'): void }>()
</script>
