import OptimiserDefaultData from "@/data/optimiserDefaultData"
import to2DP from "./to2DP"

/**
 * Calculates the total cost of fast charging based on the energy charged and time taken.
 * @param {Object} options - An object containing the following properties:
 *   @param {number} [costPerKWH] - The cost of electricity per kWh, defaults to OptimiserDefaultData.kWhCostPublic.
 *   @param {number} [costPerMin] - The cost of electricity per minute, defaults to OptimiserDefaultData.electricityCostPerMinPublic.
 *   @param {number} energyCharged - The amount of energy charged in kWh.
 *   @param {number} timeCharged - The time taken to charge in minutes.
 * @return {number} The total cost of fast charging in 2 decimal places.
 */
export function calcFastChargingCost({
  costPerKWH = OptimiserDefaultData.kWhCostPublic,
  costPerMin = OptimiserDefaultData.electricityCostPerMinPublic,
  energyCharged,
  timeCharged,
}: {
  costPerKWH: number
  costPerMin: number
  energyCharged: number
  timeCharged: number
}) {
  return to2DP(costPerKWH * energyCharged + costPerMin * timeCharged)
}

/**
 * Calculates the total cost of slow charging based on the energy charged.
 * @param {Object} options - An object containing the following properties:
 *   @param {number} [costPerKWH] - The cost of electricity per kWh, defaults to OptimiserDefaultData.kWhCostHome.
 *   @param {number} energyCharged - The amount of energy charged in kWh.
 * @return {number} The total cost of slow charging in 2 decimal places.
 */
export function calcSlowChargingCost({
  costPerKWH = OptimiserDefaultData.kWhCostHome,
  energyCharged,
}: {
  costPerKWH: number
  energyCharged: number
}) {
  return to2DP(costPerKWH * energyCharged)
}
