<template>
  <GenericDialog :activator="activator" @close="closeAndReset">
    <v-card flat>
      <v-card-title class="mb-5" v-if="!planning && !fetching"> Add a charging stop </v-card-title>
      <DescriptiveLoader v-if="planning" message="Power trip is recalculating your trip" />
      <DescriptiveLoader
        v-else-if="fetching"
        message="Power trip is finding all other compatible chargers along your trip"
      />
      <v-card-text v-else-if="!chargersInRange.length">
        There are no compatible chargers in range{{
          tripDetails
            ? '(' + Math.round(tripDetails.itinerary.totalDrivingDistance / 10 / 1000) + 'km)'
            : ''
        }}
        of this point in the trip.
      </v-card-text>
      <v-card v-else-if="selectedCharger" flat>
        <v-card class="mb-4" flat>
          <v-row no-gutters>
            <v-col cols="1" class="d-flex align-start justify-center">
              <v-img
                contain
                width="42"
                height="42"
                :src="getSrc('icons/DC.svg')"
                class="mt-5"
                aspect-ratio="1"
                max-height="42"
                max-width="42"
              />
            </v-col>
            <v-col cols="10">
              <v-card-title style="word-break: normal">
                {{ selectedCharger.name }}
              </v-card-title>
              <v-card-subtitle>
                {{ selectedCharger.addressString }}
              </v-card-subtitle>
              <v-card-text> {{ selectedCharger.evses.length }} charging bays </v-card-text>
            </v-col>
          </v-row>
          <v-card-text>
            <v-slider
              v-model="chargeTo"
              min="1"
              max="100"
              step="1"
              track-color="grey-lighten-2"
              :label="'Charge up to ' + chargeTo + '%'"
              color="primary"
              thumb-size="16"
              track-size="6"
            />

            <v-card-title> Add to leg </v-card-title>
            <v-list
              bg-color="background"
              color="primary"
            >
              <v-list-item
                v-for="(item, i) in stepOrder"
                :key="i"
                :model-value="item"
                :active="item.stepIndex === selectedStep?.stepIndex"
                @click="() => (selectedStep = item)"
              >
                <v-list-item-title>
                  {{ item.displayName }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <ElevatedBlockBtn class="mt-5" @click="addChargingStop">
              Add charging stop
            </ElevatedBlockBtn>
          </v-card-text>
        </v-card>
      </v-card>
      <v-card-text v-else>
        <v-row no-gutters justify="space-between">
          <v-card
            v-for="charger in chargersInRange"
            :key="charger.id"
            width="49%"
            class="mb-4 rounded-lg"
            color="background"
            @click="selectCharger(charger)"
          >
            <v-row no-gutters>
              <v-col cols="2" class="d-flex align-start justify-center">
                <v-img
                  contain
                  width="42"
                  height="42"
                  :src="getSrc('icons/DC.svg')"
                  class="mt-5"
                  aspect-ratio="1"
                  max-height="42"
                  max-width="42"
                />
              </v-col>
              <v-col cols="10">
                <v-card-title style="word-break: normal">
                  {{ charger.name }}
                </v-card-title>
                <v-card-subtitle>
                  {{ charger.addressString }}
                </v-card-subtitle>
                <v-card-text> {{ charger.evses.length }} charging bays </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </v-card-text>
    </v-card>
  </GenericDialog>
</template>

<script lang="ts" setup>
import { GettersTypes, MutationTypes, type State } from '@/store/store_types'
import Charger from '@/classes/charger_classes/charger'
import Trip from '@/classes/trip_classes/trip'
import getAssetSrc from '@/utils/getAssetSrc'
import ElevatedBlockBtn from '../../ui-elements/buttons/ElevatedBlockBtn.vue'
import GenericDialog from '../../dialog-wrappers/GenericDialog.vue'
import DescriptiveLoader from '../../ui-elements/loaders/DescriptiveLoader.vue'
import { useStore } from 'vuex'
import { ref, watch } from 'vue'
import { computed } from 'vue'

const store = useStore<State>()
const emit = defineEmits<{
  (e: 'close'): void
}>()
const { centerLatLng, activator = false } = defineProps<{
  centerLatLng?: { latitude: number; longitude: number } | null
  activator?: boolean
}>()

const selectedCharger = ref<Charger | null>(null)
const chargeTo = ref(80)
const planning = ref(false)
const fetching = ref(false)
const selectedStep = ref<{
  distanceScore: number
  stepIndex: number
  pointInBounds: boolean
  displayName: string
} | null>(null)

const tripDetails = computed<Trip | undefined>(() => store.getters[GettersTypes.selectedTripData])
const chargingCostPerMin = computed(() => store.state.defaultCostPerMinDC)
const publicCostPerKWh = computed(() => store.state.defaultPublicCostPerKWh)
const stations = computed(() => store.state.chargers)
const chargersInRange = computed(() => {
  if (!tripDetails.value || !centerLatLng) return []
  const chargerIds = tripDetails.value.findChargerIdsInRangeOfPoint(
    centerLatLng,
    Math.floor(tripDetails.value.itinerary.totalDrivingDistance / 10 / 1000),
  )
  return stations.value.filter((c) => chargerIds.includes(c.id)) || []
})
const stepOrder = computed<
  {
    distanceScore: number
    stepIndex: number
    pointInBounds: boolean
    displayName: string
  }[]
>(() => {
  if (!tripDetails.value || !centerLatLng) return []
  return tripDetails.value.itinerary.orderStepsByDistanceFromPoint({
    lat: centerLatLng.latitude,
    lon: centerLatLng.longitude,
  })
})

watch(
  stepOrder,
  () => {
    selectedStep.value = stepOrder.value[0]
  },
  { immediate: true },
)

function getSrc(partialFilePath: string) {
  return getAssetSrc(partialFilePath)
}
function selectCharger(charger: Charger) {
  selectedCharger.value = charger
}
function closeAndReset() {
  emit('close')
  selectedCharger.value = null
  chargeTo.value = 80
}

async function addChargingStop() {
  planning.value = true
  if (!selectedCharger.value) return
  if (!tripDetails.value) return
  if (!selectedStep.value) return
  await tripDetails.value.addChargerToStep({
    charger: selectedCharger.value,
    stepIndex: selectedStep.value.stepIndex,
    stateOfChargeAfterCharging: chargeTo.value / 100,
    defaultCostPerKWh: publicCostPerKWh.value,
    defaultCostPerMinDC: chargingCostPerMin.value,
  })
  store.commit(MutationTypes.updateIndividualTrip, tripDetails.value)
  closeAndReset()
  planning.value = false
}
async function fetchCorridorCall() {
  fetching.value = true
  if (!tripDetails.value) return
  const outcome = await tripDetails.value.getRadarData()
  if (outcome === 'SUCCESS') {
    store.commit(MutationTypes.updateIndividualTrip, tripDetails.value)
  }
  fetching.value = false
}

watch(
  () => activator,
  () => {
    if (activator) {
      fetchCorridorCall()
    }
  },
)
</script>

<style scoped>
* :deep(.v-slider--horizontal) {
  margin-left: unset;
  margin-right: unset;
}

* :deep(.v-slider__track-fill) {
  border-radius: 2px; /* override default slider border-radius */
}

* :deep(.v-slider__track-background) {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
