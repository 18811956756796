import type { DirectusNetworkBranding } from '@/types/directus-types'
import { directusReadByQuery } from './helperFunctions'

/**
 * Fetches network branding for all networks that have charging stations in DB.
 *
 * @returns A list of network branding records if successful, undefined if not.
 */
export async function fetchNetworkBranding(): Promise<DirectusNetworkBranding[] | undefined> {
  const collection = 'NetworkBranding'
  const query = { limit: -1 }

  // fetch all directus records from the NetworkBranding collection
  const res = await directusReadByQuery<DirectusNetworkBranding>(collection, query)
  return res
}
