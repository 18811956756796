<template>
  <v-dialog
    v-model="open"
    max-width="350px"
    content-class="rounded-lg"
    @click:outside="resetAndClose"
    @keydown="keyDown($event)"
  >
    <template v-slot:activator="{ props: activatorProps }">
      <v-row align="center" no-gutters class="flex-nowrap mb-3">
        <span class="pr-2"> {{ label }} </span>
        <v-text-field
          :model-value="time"
          type="time"
          readonly
          class="flex-grow-1 flex-shrink-1"
          required
          @click="openTimePicker"
          :dark="dark"
          v-bind="activatorProps"
          @click:clear="clear"
          clearable
          density="compact"
          hide-details
        />
      </v-row>
    </template>

    <v-card class="pb-2">
      <v-time-picker v-model="time" ampm-in-title format="ampm" full-width color="primary" />
      <v-card-actions>
        <ElevatedBtn class="flex-grow-1" @click="updateTime"> Update time </ElevatedBtn>
        <OutlinedBtn @click="resetAndClose"> Cancel </OutlinedBtn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue'
import ElevatedBtn from '../buttons/ElevatedBtn.vue'
import OutlinedBtn from '../buttons/OutlinedBtn.vue'

export interface TimePickerInputUpdateObj {
  identifier: string
  time: string
}

interface Props {
  initialValue?: string | null
  identifier: string
  label: string
  dark?: boolean
}

const emit = defineEmits<{
  (e: 'update', value: TimePickerInputUpdateObj): void
}>()

const { initialValue, identifier, label, dark = false } = defineProps<Props>()

const time = ref('')
const open = ref(false)

function setInitialValue() {
  if (initialValue) {
    time.value = initialValue
  } else {
    time.value = ''
  }
}

function openTimePicker() {
  open.value = true
}

function closeTimePicker() {
  open.value = false
}

function resetAndClose() {
  setInitialValue()
  closeTimePicker()
}

function updateTime() {
  emit('update', {
    identifier,
    time: time.value,
  })
  closeTimePicker()
}

function keyDown(event: KeyboardEvent) {
  if (event.key === 'Escape') resetAndClose()
}

function clear() {
  time.value = ''
  updateTime()
}

onMounted(() => {
  setInitialValue()
})
</script>
<style scoped>
.pwt-text-input-text input {
  cursor: text;
}
</style>
