<template>
  <v-card
    class="mb-3 rounded-lg pwt-hover-container"
    @click="showTrip"
    :loading="fetching"
    :disabled="fetching"
    elevation="3"
  >
    <v-row no-gutters align="center">
      <v-col :cols="trip.frequency ? 8 : 12">
        <v-card-title v-if="trip.name">
          <span class="text-truncate pwt-hover-underline"> {{ trip.name }} </span>
        </v-card-title>
        <v-card-title v-else>
          <span class="text-truncate pwt-hover-underline">
            Trip to
            {{
              trip.locations[trip.locations.length - 1].name ??
              trip.locations[trip.locations.length - 1].address
            }}
          </span>
        </v-card-title>
      </v-col>
      <v-col v-if="trip.frequency" cols="4" class="text-right pr-3">
        <v-icon color="primary" v-if="trip.frequency"> mdi-progress-clock </v-icon>
        <span class="pr-2 text-truncate" v-if="trip.frequency">
          {{ trip.frequency?.numberOfTimes }}x {{ trip.frequency?.timeFrame }}
        </span>
      </v-col>
    </v-row>
    <v-card-text>
      <ul style="list-style: none; padding-left: unset">
        <li>{{ niceDuration(trip.itinerary.totalTravelTime) }}</li>
        <li class="d-flex justify-space-between">
          {{ trip.chargingStopCDBIDs.length }} charging stops
          <OutlinedBtn @click="openDeleteConfirmation"> Delete </OutlinedBtn>
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useStore } from 'vuex'
import Trip from '@/classes/trip_classes/trip'
import { getNiceDuration } from '@/utils/timeUtils'
import {
  ActionTypes,
  GettersTypes,
  MutationTypes,
  SecondaryDialogContent,
} from '@/store/store_types'
import OutlinedBtn from '../../ui-elements/buttons/OutlinedBtn.vue'

const emit = defineEmits<{
  (e: 'view-trip', value: Trip): void
}>()

const store = useStore()

const { trip, emitOnly = false } = defineProps<{ trip: Trip; emitOnly?: boolean }>()

const fetching = computed(() => store.getters[GettersTypes.fetching])

function niceDuration(seconds: number): string {
  return getNiceDuration(seconds)
}

function openDeleteConfirmation() {
  store.commit(
    MutationTypes.setSecondaryDialogContent,
    SecondaryDialogContent.STORED_TRIP_DELETE_CONFIRMATION,
  )
  store.commit(MutationTypes.setQueuedTripForDelete, trip.localId)
}

function showTrip() {
  if (emitOnly) {
    emit('view-trip', trip)
  } else {
    store.dispatch(ActionTypes.showTrip, trip)
  }
}
</script>
<style scoped>
.pwt-hover-container:hover .pwt-hover-underline {
  text-decoration: underline;
}
</style>
