import { notifyAnalytics } from '@/api/analytics/notifyAnalytics'
import directus from '@/api/clients/directus-client'
import type User from '@/classes/user_classes/user'
import type {
  DirectusDataToUpdate,
  DirectusDriver,
  DirectusDriverDataToUpdate,
  DirectusUpdateUserRes,
  DirectusUser,
  DirectusUserDataToUpdate,
} from '@/types/directus-types'
import { uploadFile } from './file-calls'
import { readMe, updateMe } from '@directus/sdk'
import { directusCreateOne, directusUpdateOne } from './helperFunctions'

/**
 * API call to fetch the user object from directus.
 *
 * @returns User object from directus - see `fetchUserRes` for data shape.
 */
export async function fetchUser(): Promise<DirectusUser | undefined> {
  try {
    const userData = await directus.request(readMe())
    return userData as DirectusUser | undefined
  } catch (error) {
    notifyAnalytics({
      type: 'log_error',
      data: error,
    })
  }
}

/**
 * Updates the user/driver data for the current user with any new values provided.
 *
 * @param dataToUpdate object containing the data to be updated.
 * @param userID the users directus id.
 * @param driverId the users directus driver collection related item id.
 * @returns and object containing the status of the request and the new directus data objects if applicable.
 */
export async function updateUser(
  dataToUpdate: DirectusDataToUpdate,
  user: User,
): Promise<DirectusUpdateUserRes> {
  // guard clause
  if (!Object.keys(dataToUpdate).length) return { success: false } // ASSUMES: an empty object was provided and no need to update.

  let directusDriverID = user.driverDirectusId
  const driverDataToUpdate: DirectusDriverDataToUpdate = {}
  const userDataToUpdate: DirectusUserDataToUpdate = {}

  // Upload profile pic if needed.
  if (dataToUpdate.avatar) {
    try {
      const uploadRes = await uploadFile(dataToUpdate.avatar, true)
      if (uploadRes.success && uploadRes.UUID) {
        driverDataToUpdate.ProfilePic = uploadRes.UUID
        userDataToUpdate.avatar = uploadRes.UUID
      }
    } catch (error) {
      notifyAnalytics({
        type: 'log_error',
        data: error,
      })
    }
  }

  // create Driver account if needed.
  if (!directusDriverID) {
    try {
      const driverCreateRes = await directusCreateOne<DirectusDriver>('Driver', {
        Name: user.fullName,
        Email: user.email ?? null,
      })
      // const driverCreateRes = await directus.items('Driver').createOne({
      //   Name: user.fullName,
      //   Email: user.email ?? null,
      // })
      directusDriverID = (driverCreateRes as DirectusDriver).id
      userDataToUpdate.Driver = (driverCreateRes as DirectusDriver).id
    } catch (error) {
      notifyAnalytics({
        type: 'log_error',
        data: error,
      })
    }
  }

  // Prep name for updating if needed.
  if (dataToUpdate.firstName) {
    userDataToUpdate.first_name = dataToUpdate.firstName
  }
  if (dataToUpdate.lastName) {
    userDataToUpdate.last_name = dataToUpdate.lastName
  }
  if (dataToUpdate.firstName || dataToUpdate.lastName) {
    driverDataToUpdate.Name = `${dataToUpdate.firstName ?? ''} ${dataToUpdate.lastName ?? ''}`
  }

  // Prep bio for updating.
  if (dataToUpdate.bio) {
    driverDataToUpdate.Bio = dataToUpdate.bio
  }

  // Prep cars for updating.
  if (dataToUpdate.Cars) {
    userDataToUpdate.Cars = dataToUpdate.Cars
  }

  // Prep return object
  const returnObj: DirectusUpdateUserRes = {
    success: false,
  }

  // update directus collections
  if (Object.keys(userDataToUpdate).length) {
    // ASSUMES: values are present that need to be updated on the user collection as object is not empty.
    try {
      const userUpdateRes = await directus.request(updateMe(userDataToUpdate))
      returnObj.updatedUser = userUpdateRes as DirectusUser
    } catch (error) {
      notifyAnalytics({
        type: 'log_error',
        data: error,
      })
    }
  }
  if (Object.keys(driverDataToUpdate).length && directusDriverID) {
    // ASSUMES: values are present that need to be updated on the drive collection as object is not empty.
    try {
      const driverUpdateRes = await directusUpdateOne<DirectusDriver>(
        'Driver',
        directusDriverID,
        driverDataToUpdate,
      )
      // const driverUpdateRes = await directus
      //   .items('Driver')
      //   .updateOne(directusDriverID, driverDataToUpdate)
      returnObj.updatedDriver = driverUpdateRes as DirectusDriver
    } catch (error) {
      notifyAnalytics({
        type: 'log_error',
        data: error,
      })
    }
  }

  // check at least on collection was updates
  if (returnObj.updatedDriver || returnObj.updatedUser) {
    returnObj.success = true
  }

  return returnObj
}
