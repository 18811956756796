import {
  createFavouriteLocation,
  updateFavouriteLocation,
  type Directus_Favourite_Location,
} from '@/api/calls/directus-calls/favourite-locations-calls'
import generateUniqueLocalID from '../utils/generateUniqueLocalID'
import Coordinate from './common_classes/coordinate'
import TripLocation from './trip_classes/tripLocation'
import type { processedAddressObj } from '@/utils/processAddressSearchResults'

export default class FavouriteLocation {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  // global record of class instance ids this session.
  static usedIds: string[] = []

  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** Unique local id for the location */
  localId: string
  /** Coordinates of the location */
  coordinates: Coordinate
  /** User provided display name for the location */
  name: string
  /** Address of the location */
  address: string
  /** type of location this stop refers to. */
  type: 'Work' | 'Home' | 'Depot' | 'Other'
  /** The visibility of the location. */
  visibility: 'private' | 'group' | 'fleet'
  /** The planning data for the location. */
  planningData?: PlanningData
  /** The directus id of the location. */
  directusId?: number

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Constructs a new instance of favouriteLocation.
   *
   * @param options - An object containing the following properties:
   *  - coordinates: Coordinate - The coordinates of the location.
   *  - localId: string (optional) - The unique local id for the location.
   *    If not provided, a new unique id will be generated.
   *  - name: string (optional) - The user provided display name for the location.
   *  - address: string (optional) - The address of the location.
   *  - type: "Work" | "Home" | "Depot" | "Other" (optional) - The type of location this stop refers to.
   *  - visibility: "private" | "group" | "fleet" (optional) - The visibility of the location.
   *  - planningData: PlanningData (optional) - The planning data for the location.
   *  - directusId: number (optional) - The directus id of the location.
   */
  constructor({
    coordinates,
    localId = undefined,
    name,
    address,
    type = 'Other',
    visibility = 'private',
    planningData = undefined,
    directusId = undefined,
  }: {
    /** The coordinates of the location. */
    coordinates: Coordinate
    /** The unique local id for the location. */
    localId?: string
    /** The user provided display name for the location. */
    name: string
    /** The address of the location. */
    address: string
    /** The type of location this stop refers to. */
    type?: 'Work' | 'Home' | 'Depot' | 'Other'
    /** The visibility of the location. */
    visibility?: 'private' | 'group' | 'fleet'
    /** The planning data for the location. */
    planningData?: PlanningData
    /** The directus id of the location. */
    directusId?: number
  }) {
    this.localId = localId ?? generateUniqueLocalID(FavouriteLocation.usedIds, 'favouriteLocation')
    this.coordinates = coordinates
    this.name = name
    this.address = address
    this.type = type
    this.visibility = visibility
    this.planningData = planningData
    this.directusId = directusId

    // add id to list of used unique ids
    FavouriteLocation.usedIds.push(this.localId)
  }

  /**
   * Compile planning data based on the Directus_Favourite_Location data and create a new FavouriteLocation instance.
   *
   * @param {Directus_Favourite_Location} data - The Directus_Favourite_Location data to convert.
   * @return {FavouriteLocation} The new FavouriteLocation instance created from the Directus data.
   */
  static fromDirectusData(data: Directus_Favourite_Location): FavouriteLocation {
    // compile planning data
    const planningData: PlanningData = {}
    if (data.Charge_Here) {
      planningData.chargeHere = data.Charge_Here
    }
    if (data.Rating) {
      planningData.rating = data.Rating
    }
    if (data.Current_Type) {
      planningData.currentType = data.Current_Type
    }
    if (data.Load_Weight_Change) {
      planningData.loadWeightChange = data.Load_Weight_Change
    }
    if (data.SOC_after_charging) {
      planningData.SOCAfterCharging = data.SOC_after_charging
    }
    if (data.energy_used) {
      planningData.energyUsed = data.energy_used
    }
    if (data.stay_duration_seconds) {
      planningData.stayDuration = data.stay_duration_seconds
    }
    // compile and return new instance
    return new FavouriteLocation({
      coordinates: Coordinate.fromGEOJsonPoint(data.Coordinates),
      name: data.Name,
      address: data.Address,
      type: data.Type,
      visibility: data.Visibility,
      directusId: data.id,
      planningData: Object.keys(planningData).length > 0 ? planningData : undefined,
    })
  }

  /**
   * Creates a new FavouriteLocation instance from a processed address object.
   *
   * @param obj - The processed address object to create a new FavouriteLocation instance from.
   * @return {FavouriteLocation} The new FavouriteLocation instance created from the provided processed address object.
   */
  static fromProcessedAddressObj(obj: processedAddressObj): FavouriteLocation {
    if (!obj || typeof obj !== 'object') {
      throw new Error('Invalid input: expected an object')
    }

    if (!obj.coordinates || !Coordinate.fromCapitalizedObj(obj.coordinates)) {
      throw new Error('Invalid input: unable to parse coordinates')
    }

    if (!obj.address || typeof obj.address !== 'string') {
      throw new Error('Invalid input: address must be a string')
    }

    return new FavouriteLocation({
      coordinates: Coordinate.fromCapitalizedObj(obj.coordinates),
      name: obj.name ?? '',
      address: obj.address,
      type: 'Other',
      visibility: 'private',
      localId: obj.localId,
    })
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Returns a processed address object based on the current instance's address, name, and coordinates.
   *
   * @return {processedAddressObj} The processed address object with the following properties:
   * - coordinates: The capitalized coordinates of the current instance.
   * - address: A string concatenation of the current instance's name and address.
   * - localId: The local ID of the current instance.
   *
   * @throws {Error} If the current instance's address or name is falsy.
   */
  public get toProcessedAddressObj(): processedAddressObj {
    if (!this.address) {
      throw new Error('No address provided')
    }
    if (!this.name) {
      throw new Error('No name provided')
    }

    return {
      coordinates: this.coordinates.asCapitalizedObj,
      address: this.name + ', ' + this.address,
      name: this.name,
      localId: this.localId,
    }
  }

  /**
   * Compiles the data to be sent to Directus for a Favourite Location update.
   *
   * @return {Omit<Directus_Favourite_Location, "id" | "user_created">} The compiled data for Directus.
   */
  public get toDirectusData(): Omit<Directus_Favourite_Location, 'id' | 'user_created'> {
    return {
      Name: this.name,
      Address: this.address,
      Coordinates: {
        type: 'Point',
        coordinates: [this.coordinates.longitude, this.coordinates.latitude],
      },
      Type: this.type,
      Visibility: this.visibility,
      Charge_Here: this.planningData?.chargeHere ?? false,
      Rating: this.planningData?.rating ?? null,
      Current_Type: this.planningData?.currentType ?? null,
      Load_Weight_Change: this.planningData?.loadWeightChange ?? null,
      SOC_after_charging: this.planningData?.SOCAfterCharging ?? null,
      energy_used: this.planningData?.energyUsed ?? null,
      stay_duration_seconds: this.planningData?.stayDuration ?? null,
    }
  }

  /**
   * Compiles the data to be sent to TripLocation for a Favourite Location.
   *
   * @return {TripLocation} The compiled data for TripLocation.
   */
  public get toTripLocation(): TripLocation {
    return new TripLocation({
      coordinates: this.coordinates,
      name: this.name,
      address: this.address,
      chargeHere: this.planningData?.chargeHere ?? false,
      stateOfChargeAfterCharging: this.planningData?.SOCAfterCharging,
      nonDrivingEnergyUsed: this.planningData?.energyUsed,
      kWChargerRating: this.planningData?.rating,
      stay: this.planningData?.stayDuration,
      weightChange: this.planningData?.loadWeightChange,
    })
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Methods ----------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Save favourite location to Directus.
   *
   * @return {Promise<"failed" | "success">} - Promise indicating success or failure
   */
  public async saveFavouriteLocation(): Promise<'failed' | 'success'> {
    let outcome = 'failed' as 'failed' | 'success'
    if (this.directusId) {
      const res = await updateFavouriteLocation(this.directusId, this.toDirectusData)
      if (res) {
        outcome = 'success'
      }
    } else {
      const res = await createFavouriteLocation(this.toDirectusData)
      if (res) {
        outcome = 'success'
        this.directusId = res.id
      }
    }
    return outcome
  }
}

// TODO: Add tests
// TODO: Add history
// TODO: Add frequency

/**
 * Interface representing the data that is stored in the `planningData` field of a `favouriteLocation` instance.
 *
 * Used as default data if this location is selected in trip planning.
 */
export interface PlanningData {
  /** If true, indicates that the location has a charger that can be used for charging the vehicle. */
  chargeHere?: boolean
  /** The user's rating for the location. */
  rating?: number
  /** The type of charger that is available at the location. */
  currentType?: 'AC' | 'DC'
  /** The weight change that is required to use the charger at the location. */
  loadWeightChange?: number
  /** The normal SOC of a vehicle when it has completed charging at the location. */
  SOCAfterCharging?: number
  /** The normal energy used for idle processes at the location. */
  energyUsed?: number
  /** The normal stay duration in seconds at the location. */
  stayDuration?: number
}
